import moment from 'moment'

const instruments = [
  [`acc`, `accordion`],
  [`afl`, `alto flute`],
  [`alt`, `alto (voice) (contralto)`],
  [`arp`, `arpeggione`],
  [`bag`, `bagpipe`],
  [`bar`, `baritone (voice)`],
  [`bass`, `bass (voice)`],
  [`bbar`, `bass baritone (voice)`],
  [`bc`, `continuo (basso continuo)`],
  [`bcl`, `bass clarinet`],
  [`bell`, `bell (chimes)`],
  [`bfl`, `bass flute`],
  [`bgtr`, `bass guitar`],
  [`bjo`, `banjo`],
  [`bn`, `bassoon`],
  [`bob`, `bass oboe (baritone oboe)`],
  [`br`, `brass instruments`],
  [`bryt`, `baryton`],
  [`bstcl`, `basset clarinet`],
  [`bsthn`, `basset horn`],
  [`bug`, `bugle`],
  [`cbcl`, `contrabass clarinet`],
  [`cbn`, `contrabassoon`],
  [`cch`, `children's chorus`],
  [`cel`, `celesta`],
  [`ch`, `mixed chorus`],
  [`cimb`, `cimbalom`],
  [`cit`, `cittern`],
  [`cl`, `clarinet`],
  [`clvd`, `clavichord`],
  [`cm`, `chalumeau`],
  [`conc`, `concertina`],
  [`crh`, `crumhorn`],
  [`crt`, `cornet`],
  [`cv`, `child's voice`],
  [`db`, `double bass`],
  [`dlcn`, `dulcian`],
  [`dom`, `domra`],
  [`dulc`, `dulcimer`],
  ['drums', 'drum kit'],
  [`egtr`, `electric guitar`],
  [`eh`, `english horn (cor anglais)`],
  [`elec`, `electronics`],
  [`epf`, `electric piano`],
  [`eq`, `equal voices`],
  [`euph`, `euphonium`],
  [`fch`, `female chorus`],
  [`fda`, `flute d'amore (tenor flute)`],
  [`fgh`, `flugelhorn`],
  [`fife`, `fife`],
  [`fl`, `flute`],
  [`flag`, `flageolet`],
  [`ghca`, `glass harmonica (bowl organ)`],
  [`gl`, `glockenspiel`],
  [`gtr`, `guitar`],
  [`harm`, `harmonium`],
  [`hca`, `harmonica (mouth organ)`],
  [`heck`, `heckelphone`],
  [`hn`, `horn`],
  [`hp`, `harp`],
  [`hpd`, `harpsichord`],
  [`kbd`, `keyboard`],
  [`lute`, `lute`],
  [`lyre`, `lyre`],
  [`mand`, `mandolin`],
  [`mar`, `marimba`],
  [`mch`, `male chorus`],
  [`mez`, `mezzo-soprano`],
  [`mus`, `musette`],
  [`nar`, `narrator (reciter)`],
  [`ob`, `oboe`],
  [`oca`, `ocarina`],
  [`oda`, `oboe d'amore`],
  [`om`, `ondes martenot`],
  [`oph`, `ophicleide`],
  [`orch`, `orchestra`],
  [`org`, `organ`],
  [`oud`, `oud`],
  [`pan`, `pan flute (pan-pipes)`],
  [`perc`, `percussion`],
  [`pf`, `piano`],
  [`pf3h`, `piano 3 hands`],
  [`pf4h`, `piano 4 hands`],
  [`pf5h`, `piano 5 hands`],
  [`pf6h`, `piano 6 hands`],
  [`pflh`, `piano left hand`],
  [`pfped`, `pedal piano`],
  [`pfrh`, `piano right hand`],
  [`picc`, `piccolo`],
  [`pipa`, `pipa`],
  [`pk`, `timpani`],
  [`ptpt`, `piccolo trumpet`],
  [`reb`, `rebec`],
  [`rec`, `recorder`],
  [`sar`, `sarrusophone`],
  [`sax`, `saxophone`],
  [`s.sax`, 'soprano saxophone'],
  [`a.sax`, 'alto saxophone'],
  [`t.sax`, 'tenor saxophone'],
  [`b.sax`, 'baritone saxophone'],
  [`sheng`, `sheng`],
  [`shw`, `shawm`],
  [`sit`, `sitar`],
  [`skbt`, `sackbut`],
  [`sop`, `soprano (voice)`],
  [`srp`, `serpent`],
  [`stpt`, `slide trumpet`],
  [`str`, `string instruments`],
  [`sxh`, `saxhorn`],
  [`syn`, `synthesizer`],
  [`tba`, `tuba`],
  [`tbn`, `trombone`],
  [`btbn`, 'bass trombone'],
  [(`ten`, `tenor`)],
  [`thrm`, `theremin`],
  [`timp`, `timpani`],
  [`tpt`, `trumpet`],
  [`uch`, `unison chorus`],
  [`uke`, `ukelele (ukulele)`],
  [`v`, `voice (solo)`],
  [`va`, `viola`],
  [`vap`, `viola pomposa`],
  [`vc`, `cello`],
  [`vda`, `viola d'amore`],
  [`vib`, `vibraphone`],
  [`vie`, `vielle (hurdy-gurdy)`],
  [`viol`, `viol (viola da gamba)`],
  [`vlne`, `violone`],
  [`vn`, `violin`],
  [`vuv`, `vuvuzela`],
  [`vv`, `voices (multiple soloists)`],
  [`wag`, `wagner tuba`],
  [`ww`, `woodwind instruments`],
  [`xiao`, `xiao`],
  [`xyl`, `xylophone`],
  [`zith`, `zither`],
]

export function instrumentAbbreviation(search) {
  const tuple = instruments.find((item) => item.includes(search.toLowerCase()))
  return typeof tuple === 'undefined'
    ? search
    : tuple.filter((word) => word !== search.toLowerCase())[0]
}

export const formatPhone = (original) => {
  let num = original.replace(/\D/g, '')
  if (num.substring(0, 1) === '1') {
    num = num.slice(1)
  }
  if (num.length <= 3) {
    return num
  } else if (num.length > 3 && num.length <= 6) {
    return num.substring(0, 3) + '-' + num.substring(3, 6)
  } else {
    return (
      num.substring(0, 3) +
      '-' +
      num.substring(3, 6) +
      '-' +
      num.substring(6, 10)
    )
  }
}

export function formatTelLink(phone) {
  let newPhone = phone.replace(/\D/g, '').toString()
  if (newPhone.substring(0, 1) !== '1') {
    newPhone = '1' + newPhone
  }
  return 'tel:+' + newPhone
}

export function getErrorMessage(data, response) {
  if (!!data && !!data.error && typeof data.error === 'string') {
    return data.error
  }
  if (!!data && !!data.error && !!data.error.message) {
    return data.error.message
  }
  if (!!data && !!data.error && !!data.error.msg) {
    return data.error.msg
  }
  if (!!data && !!data.error && !!data.error.error) {
    return data.error.error
  }
  return response.statusText
}

export function handleError(err) {
  console.error(err)
  return new Response(err)
}

export function is2xxStatus(status) {
  const s = !!status ? parseInt(status) : 0
  return s >= 200 && s < 300
}

export function titleCase(text) {
  return typeof text === 'string' && text.length
    ? text
        .trim()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
        .split('/')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('/')
    : text
}

/**
 * Utility functions to help format user info before submission
 * @param {Object} newUserInfo
 * @param {Func} setFunc
 */

export function sanitizeFormValue(value, field) {
  switch (field) {
    case 'zip':
      return value.replace(/\D/g, '').substring(0, 5)
    case 'phone':
      return formatPhone(value)
    default:
      return value
  }
}

export function findUrl(obj) {
  let result = null
  if (typeof obj === 'string' && obj.indexOf('https://') === 0) {
    return obj
  }
  if (typeof obj === 'object' && obj !== null) {
    const keys = Object.keys(obj)
    for (let i = 0; i < keys.length; i++) {
      result = findUrl(obj[keys[i]])
      if (result) {
        break
      }
    }
  }
  return result
}

export function removeTrailingSlash(path) {
  if (path === '/') {
    return path
  }
  return path.slice(-1) === '/' ? path.slice(0, -1) : path
}

export function getValidDates(date, gitAuthorTime, gitCreatedTime) {
  const mDate =
    !!date && typeof date === 'string' && date.replace(/\D/g, '').length
      ? moment(date, 'YYYY-MM-DD')
      : null
  const mCreate = moment(gitCreatedTime)
  const mModified =
    !!gitAuthorTime && gitAuthorTime !== 'Invalid Date'
      ? moment(gitAuthorTime)
      : null

  const output = {
    date: !!mDate && mDate.isValid() ? mDate : mCreate,
  }
  output.dateModified =
    mModified && mModified.isValid() ? mModified : output.date
  return output
}
