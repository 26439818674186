import { Element } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

export const featuredImageProps = PropTypes.shape({
  alt: PropTypes.string.isRequired,
  caption_MD: PropTypes.string,
  src: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.object.isRequired,
      original: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
    }).isRequired,
  }),
})

export const fieldsProps = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  gitAuthorTime: PropTypes.string,
  gitCreatedTime: PropTypes.string,
  inlineImages: PropTypes.arrayOf(
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }),
    }),
  ),
})

export const panelProps = {
  type: PropTypes.string.isRequired,
  align: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  subtitle_MD: PropTypes.string,
  extraClasses: PropTypes.array,
  padding: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }),
  subnavAnchor: PropTypes.string,
  inlineImages: PropTypes.array,
}

export const heroProps = {
  header: PropTypes.string,
  subheader_MD: PropTypes.string,
  featuredImage: featuredImageProps,
  heroRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  date: PropTypes.instanceOf(moment().constructor),
  dateModified: PropTypes.instanceOf(moment().constructor),
}

export const pieceMetaProps = {
  instrumentation: PropTypes.arrayOf(PropTypes.string),
  duration: PropTypes.string,
  performers: PropTypes.string,
  commissioner: PropTypes.string,
  compositionDate: PropTypes.string,
  premiere: PropTypes.string,
  score: PropTypes.string,
  code: PropTypes.string,
  media: PropTypes.arrayOf(PropTypes.string),
}

export const frontmatterProps = PropTypes.shape({
  title: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  date: PropTypes.string,
  canonical: PropTypes.string,
  featuredImage: featuredImageProps,
  hero: PropTypes.shape(heroProps),
  template: PropTypes.string,
  pieceMeta: PropTypes.shape(pieceMetaProps),
  panels: PropTypes.arrayOf(PropTypes.shape(panelProps)),
  categories: PropTypes.arrayOf(PropTypes.string),
  primaryCategory: PropTypes.string,
})

export const siteProps = PropTypes.shape({
  siteMetadata: PropTypes.shape({
    location: PropTypes.shape({
      origin: PropTypes.string.isRequired,
      hostname: PropTypes.string.isRequired,
      protocol: PropTypes.string.isRequired,
    }),
    twitterHandle: PropTypes.string,
  }),
})
