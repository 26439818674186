import React, { forwardRef, useCallback, Fragment } from 'react'
import Img from 'gatsby-image'
import { heroProps } from '../proptypes'
import HTMLContent from './HTMLContent'
import { useInView } from 'react-intersection-observer'

import './Hero.sass'

const Hero = forwardRef(
  (
    { featuredImage, header, subheader_MD, heroRef, date, dateModified },
    ref,
  ) => {
    const [inViewRef, inView] = useInView({ rootMargin: '-50px' })

    const setRefs = useCallback(
      (node) => {
        // Ref's from useRef needs to have the node assigned to `current`
        ref.current = node
        // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
        inViewRef(node)
      },
      [inViewRef, ref],
    )
    const heroClasses = [
      'hero',
      inView ? 'in-view' : '',
      !!featuredImage ? 'has-hero-img' : 'no-hero-img',
    ]
      .filter((item) => item)
      .join(' ')

    return (
      <section id="hero" className={heroClasses} ref={setRefs}>
        {!!featuredImage &&
          !!featuredImage.src &&
          !!featuredImage.src.childImageSharp && (
            <div className="hero-img-holder">
              <Img
                fluid={featuredImage.src.childImageSharp.fluid}
                alt={featuredImage.alt}
                className="hero-img"
              />
              {!!featuredImage && !!featuredImage.caption_MD && (
                <HTMLContent
                  className="hero-img-credit"
                  content={featuredImage.caption_MD}
                />
              )}
            </div>
          )}
        <div className="hero-content" ref={heroRef}>
          <h1
            className="hero-content-title"
            dangerouslySetInnerHTML={{ __html: header }}
          />
          {!!subheader_MD && (
            <HTMLContent
              content={subheader_MD}
              className="hero-content-subtitle"
            />
          )}
          {!!date && (
            <div className="post-meta">
              <time className="post-time" dateTime={date.format('YYYY-MM-DD')}>
                Posted: {date.format('MMMM D, YYYY')}
              </time>
              {!!dateModified &&
                dateModified.isValid() &&
                !dateModified.startOf('day').isSame(date.startOf('day')) && (
                  <Fragment>
                    <span style={{ padding: '0 0.5em' }}>|</span>
                    <time
                      className="post-time"
                      dateTime={dateModified.format('YYYY-MM-DD')}
                    >
                      Updated: {dateModified.format('MMMM D, YYYY')}
                    </time>
                  </Fragment>
                )}
            </div>
          )}
        </div>
        <div className="hero-content-divider" />
      </section>
    )
  },
)

Hero.propTypes = heroProps

export default Hero
