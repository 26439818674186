import { useStaticQuery, graphql } from 'gatsby'

export default () => {
  const query = useStaticQuery(
    graphql`
      query RecentArticlesListQuery {
        allMarkdownRemark(
          filter: { frontmatter: { template: { eq: "blog" } } }
          sort: { order: DESC, fields: frontmatter___date }
          limit: 5
        ) {
          edges {
            node {
              fields {
                slug
                gitAuthorTime
                gitCreatedTime
              }
              excerpt(format: PLAIN, pruneLength: 250)
              frontmatter {
                title
                date
                featuredImage {
                  alt
                  src {
                    childImageSharp {
                      fluid(maxWidth: 420, maxHeight: 420, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  )
  return query.allMarkdownRemark.edges
}
