import React from 'react'
// import { v4 as uuidv4 } from 'uuid'
import { Helmet } from 'react-helmet'

const fontlist = [
  [
    "font-family:'Josefin Sans'",
    'font-style:normal',
    'font-weight:300',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/josefinsans/v16/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_GbQbMlhLzTs.woff2) format('woff2')",
    'unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
  ],
  /* latin-ext */
  [
    "font-family:'Josefin Sans'",
    'font-style:normal',
    'font-weight:300',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/josefinsans/v16/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_GbQbMhhLzTs.woff2) format('woff2')",
    'unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  ],
  /* latin */
  [
    "font-family:'Josefin Sans'",
    'font-style:normal',
    'font-weight:300',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/josefinsans/v16/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_GbQbMZhLw.woff2) format('woff2')",
    'unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  ],
  /* vietnamese */
  [
    "font-family:'Josefin Sans'",
    'font-style:italic',
    'font-weight:500',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/josefinsans/v16/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCThoJRIizoYV0.woff2) format('woff2')",
    'unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
  ],
  /* latin-ext */
  [
    "font-family:'Josefin Sans'",
    'font-style:italic',
    'font-weight:500',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/josefinsans/v16/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCThoJRIyzoYV0.woff2) format('woff2')",
    'unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  ],
  /* latin */
  [
    "font-family:'Josefin Sans'",
    'font-style:italic',
    'font-weight:500',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/josefinsans/v16/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCThoJRLSzo.woff2) format('woff2')",
    'unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  ],
  /* vietnamese */
  [
    "font-family:'Josefin Sans'",
    'font-style:normal',
    'font-weight:500',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/josefinsans/v16/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ArQbMlhLzTs.woff2) format('woff2')",
    'unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
  ],
  /* latin-ext */
  [
    "font-family:'Josefin Sans'",
    'font-style:normal',
    'font-weight:500',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/josefinsans/v16/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ArQbMhhLzTs.woff2) format('woff2')",
    'unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  ],
  /* latin */
  [
    "font-family:'Josefin Sans'",
    'font-style:normal',
    'font-weight:500',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/josefinsans/v16/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ArQbMZhLw.woff2) format('woff2')",
    'unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  ],
  /* cyrillic-ext */
  [
    "font-family:'Raleway'",
    'font-style:italic',
    'font-weight:400',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptsg8zYS_SKggPNyCg4QIFqPfE.woff2) format('woff2')",
    'unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
  ],
  /* cyrillic */
  [
    "font-family:'Raleway'",
    'font-style:italic',
    'font-weight:400',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptsg8zYS_SKggPNyCg4SYFqPfE.woff2) format('woff2')",
    'unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  ],
  /* vietnamese */
  [
    "font-family:'Raleway'",
    'font-style:italic',
    'font-weight:400',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptsg8zYS_SKggPNyCg4QoFqPfE.woff2) format('woff2')",
    'unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
  ],
  /* latin-ext */
  [
    "font-family:'Raleway'",
    'font-style:italic',
    'font-weight:400',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptsg8zYS_SKggPNyCg4Q4FqPfE.woff2) format('woff2')",
    'unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  ],
  /* latin */
  [
    "font-family:'Raleway'",
    'font-style:italic',
    'font-weight:400',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptsg8zYS_SKggPNyCg4TYFq.woff2) format('woff2')",
    'unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  ],
  /* cyrillic-ext */
  [
    "font-family:'Raleway'",
    'font-style:italic',
    'font-weight:600',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptsg8zYS_SKggPNyCg4QIFqPfE.woff2) format('woff2')",
    'unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
  ],
  /* cyrillic */
  [
    "font-family:'Raleway'",
    'font-style:italic',
    'font-weight:600',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptsg8zYS_SKggPNyCg4SYFqPfE.woff2) format('woff2')",
    'unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  ],
  /* vietnamese */
  [
    "font-family:'Raleway'",
    'font-style:italic',
    'font-weight:600',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptsg8zYS_SKggPNyCg4QoFqPfE.woff2) format('woff2')",
    'unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
  ],
  /* latin-ext */
  [
    "font-family:'Raleway'",
    'font-style:italic',
    'font-weight:600',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptsg8zYS_SKggPNyCg4Q4FqPfE.woff2) format('woff2')",
    'unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  ],
  /* latin */
  [
    "font-family:'Raleway'",
    'font-style:italic',
    'font-weight:600',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptsg8zYS_SKggPNyCg4TYFq.woff2) format('woff2')",
    'unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  ],
  /* cyrillic-ext */
  [
    "font-family:'Raleway'",
    'font-style:normal',
    'font-weight:400',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptug8zYS_SKggPNyCAIT5lu.woff2) format('woff2')",
    'unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
  ],
  /* cyrillic */
  [
    "font-family:'Raleway'",
    'font-style:normal',
    'font-weight:400',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptug8zYS_SKggPNyCkIT5lu.woff2) format('woff2')",
    'unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  ],
  /* vietnamese */
  [
    "font-family:'Raleway'",
    'font-style:normal',
    'font-weight:400',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptug8zYS_SKggPNyCIIT5lu.woff2) format('woff2')",
    'unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
  ],
  /* latin-ext */
  [
    "font-family:'Raleway'",
    'font-style:normal',
    'font-weight:400',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptug8zYS_SKggPNyCMIT5lu.woff2) format('woff2')",
    'unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  ],
  /* latin */
  [
    "font-family:'Raleway'",
    'font-style:normal',
    'font-weight:400',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptug8zYS_SKggPNyC0ITw.woff2) format('woff2')",
    'unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  ],
  /* cyrillic-ext */
  [
    "font-family:'Raleway'",
    'font-style:normal',
    'font-weight:600',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptug8zYS_SKggPNyCAIT5lu.woff2) format('woff2')",
    'unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
  ],
  /* cyrillic */
  [
    "font-family:'Raleway'",
    'font-style:normal',
    'font-weight:600',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptug8zYS_SKggPNyCkIT5lu.woff2) format('woff2')",
    'unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  ],
  /* vietnamese */
  [
    "font-family:'Raleway'",
    'font-style:normal',
    'font-weight:600',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptug8zYS_SKggPNyCIIT5lu.woff2) format('woff2')",
    'unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
  ],
  /* latin-ext */
  [
    "font-family:'Raleway'",
    'font-style:normal',
    'font-weight:600',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptug8zYS_SKggPNyCMIT5lu.woff2) format('woff2')",
    'unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  ],
  /* latin */
  [
    "font-family:'Raleway'",
    'font-style:normal',
    'font-weight:600',
    'font-display:swap',
    "src:url(https://fonts.gstatic.com/s/raleway/v17/1Ptug8zYS_SKggPNyC0ITw.woff2) format('woff2')",
    'unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  ],
]

export default () => (
  <Helmet>
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <style>
      {fontlist.map((parts) => `@font-face{${parts.join(';')}}`).join('')}
    </style>
  </Helmet>
)
