import { useStaticQuery, graphql } from 'gatsby'

const useDefaultArchiveImage = () => {
  const query = useStaticQuery(
    graphql`
      query DefaultArchiveImageQuery {
        image: file(absolutePath: { regex: "/aaron-steps-fallback.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 420, maxHeight: 420, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )
  return {
    alt: 'Aaron Gervais sitting on steps in Golden Gate Park',
    src: query.image,
  }
}

export default useDefaultArchiveImage
