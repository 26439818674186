import { useStaticQuery, graphql } from 'gatsby'

export default () => {
  const query = useStaticQuery(
    graphql`
      query DefaultImageQuery {
        image: file(absolutePath: { regex: "/aaron-steps-fallback.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
            original {
              height
              width
            }
          }
        }
      }
    `,
  )
  return {
    alt: 'Aaron Gervais sitting on steps in Golden Gate Park',
    src: query.image,
  }
}
