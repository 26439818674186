import smoothscroll from './smoothscroll-polyfill'
smoothscroll.polyfill()

const scrollTo = (ref, callback) => {
  if (typeof window === 'undefined') return

  const top = Math.floor(ref.current.offsetTop - 10)

  function callbackListener() {
    const target = top < 0 ? 0 : top
    const y = window.pageYOffset
    if (y - target > -1 && y - target < 1) {
      window.removeEventListener('scroll', callbackListener)
      callback()
    }
  }

  if (callback) {
    window.addEventListener('scroll', callbackListener)
  }

  window.scrollTo({
    left: 0,
    top,
    behavior: 'smooth',
  })
}
export default scrollTo
