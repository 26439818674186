import { useStaticQuery, graphql } from 'gatsby'

const useNavStructure = () => {
  const query = useStaticQuery(
    graphql`
      query QuerySiteNav {
        site {
          siteMetadata {
            navItems: nav {
              link
              label
            }
          }
        }
      }
    `,
  )

  return query.site.siteMetadata
}

export default useNavStructure
