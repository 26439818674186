import { getValidDates } from '../utils'

export default (data, extraProps) => {
  const {
    frontmatter: { title, metaDescription, template, hero, canonical },
    fields: { date: fieldDate },
  } = data.markdownRemark

  const featuredImage = data.markdownRemark.frontmatter.featuredImage || null
  const { slug, gitAuthorTime, gitCreatedTime } = data.markdownRemark.fields

  const dates = getValidDates(fieldDate, gitAuthorTime, gitCreatedTime)

  const niceTitle =
    hero && hero.header && hero.header.title ? hero.header.title : title

  const props = {
    title,
    niceTitle,
    description: metaDescription,
    template,
    canonical,
    slug,
    date: dates.date.format('YYYY-MM-DD'),
    dateModified: dates.dateModified.format('YYYY-MM-DD'),
  }
  if (featuredImage) {
    props.featuredImage = featuredImage
  }
  return !!extraProps ? Object.assign(props, extraProps) : props
}
