import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faSoundcloud,
} from '@fortawesome/free-brands-svg-icons'
import './SocialNav.sass'

const icons = {
  faFacebook,
  faTwitter,
  faInstagram,
  faSoundcloud,
}

const useSocialNav = () => {
  const query = useStaticQuery(
    graphql`
      query QuerySocialLinks {
        site {
          siteMetadata {
            social {
              link
              label
            }
          }
        }
      }
    `,
  )

  return query.site.siteMetadata
}

export default () => {
  const { social } = useSocialNav()
  return (
    <nav id="social-media-links" className="nav social-nav">
      {social.map(({ label, link }) => (
        <div key={uuidv4()} className="nav-item social-nav-item">
          <OutboundLink
            href={link}
            target="_blank"
            className="nav-link social-nav-link"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              className="social-nav-icon"
              icon={
                icons[
                  `fa${label.slice(0, 1).toUpperCase()}${label
                    .slice(1)
                    .toLowerCase()}`
                ]
              }
            />
          </OutboundLink>
        </div>
      ))}
    </nav>
  )
}
