import React, {
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
  useEffect,
  Element,
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { scrollTo } from '../hooks'

import './SubNav.sass'

const SubNavItem = ({ targetRef, label, setNavOpen, setInView, inView }) => {
  const wrapperClasses = ['subnav-item', 'nav-item', inView ? 'in-view' : '']
    .filter((x) => x)
    .join(' ')
  return (
    <div className={wrapperClasses}>
      <button
        className="nav-link subnav-link"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          scrollTo(targetRef, () => {
            setInView()
          })
          setNavOpen(false)
        }}
      >
        {label}
      </button>
    </div>
  )
}

SubNavItem.propTypes = {
  targetRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  label: PropTypes.string.isRequired,
  setNavOpen: PropTypes.func.isRequired,
  setInView: PropTypes.func.isRequired,
  inView: PropTypes.bool.isRequired,
}

const SubNav = ({ items, targetRefs, setNavOpen }) => {
  const [inView, setInView] = useState(null)
  const firstRender = useRef(false)

  // Add hero and add bucket for offsetTop
  const itemsPlusHero = [
    { label: 'Top of Page', link: 'hero', refIndex: -1 },
    ...items,
  ].map((item) => ({ ...item, offsetTop: null, refIndex: item.refIndex + 1 })) // +1 to account for hero

  const getOffsets = useCallback(() => {
    if (
      !!targetRefs &&
      !!targetRefs.length &&
      targetRefs.indexOf(undefined) === -1 &&
      targetRefs.map((ref) => !!ref && !!ref.current).indexOf(false) === -1
    ) {
      itemsPlusHero.forEach((item, i) => {
        const ref = targetRefs
          .filter((ref) => ref.current)
          .find((ref) => ref.current.id === item.link)
        // add a 20 px buffer
        item.offsetTop =
          !!ref && !!ref.current
            ? ref.current.offsetTop === 0
              ? 0
              : ref.current.offsetTop - 50
            : null
      })
    }
  }, [targetRefs, itemsPlusHero])

  useEffect(() => {
    getOffsets()
    window.addEventListener('resize', getOffsets)
    return () => window.removeEventListener('resize', getOffsets)
  }, [getOffsets])

  useLayoutEffect(() => {
    function findCurrent(y) {
      // populate values if blank
      if (!itemsPlusHero[1].offsetTop) {
        getOffsets()
      }
      let i = 0
      for (; i < itemsPlusHero.length; i++) {
        const cur = itemsPlusHero[i]
        const next = itemsPlusHero[i + 1]
        if (typeof next === 'undefined') break
        if (y >= cur.offsetTop && y < next.offsetTop) break
      }
      return itemsPlusHero[i].link
    }
    function checkInView() {
      // find the first visible ref and set if different
      const current = findCurrent(window.pageYOffset)
      if (current !== inView) {
        setInView(current)
      }
    }
    if (!firstRender.current) {
      firstRender.current = true
      window.addEventListener('scroll', checkInView)
    }
    return () => {
      firstRender.current = false
      window.removeEventListener('scroll', checkInView)
    }
  }, [itemsPlusHero, setInView, inView, getOffsets])

  return (
    <div className="subnav-holder">
      <nav className="subnav nav">
        {itemsPlusHero.map(({ label, link, refIndex }) => (
          <SubNavItem
            key={uuidv4()}
            targetRef={targetRefs[refIndex]}
            label={label}
            setNavOpen={setNavOpen}
            setInView={() => {
              setInView(link)
            }}
            inView={inView === link}
          />
        ))}
      </nav>
    </div>
  )
}

SubNav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.string.isRequired,
      refIndex: PropTypes.number.isRequired,
    }),
  ).isRequired,
  targetRefs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
  ).isRequired,
  setNavOpen: PropTypes.func.isRequired,
}

export default SubNav
