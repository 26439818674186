import React from 'react'
import { Link } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'
import { useNavStructure } from '../hooks'

import './Footer.sass'

const NavItem = ({ link, label }) => (
  <div className={`footer-nav-item nav-item`}>
    <Link to={link} className="footer-nav-link nav-link">
      {label}
    </Link>
  </div>
)

const Footer = () => {
  const { navItems } = useNavStructure()
  const date = new Date()

  return (
    <footer className="footer" id="footer">
      <div className="footer-inner">
        <div className="footer-content">
          <div className="footer-title">
            <h3>Aaron Gervais</h3>
            <em>Brewer, Composer</em>
          </div>
          <nav id="footer-nav" className="footer-nav nav">
            {navItems.map(({ label, link }) => (
              <NavItem key={uuidv4()} label={label} link={link} />
            ))}
          </nav>
        </div>
        <div className="copyright">
          Copyright &copy; Aaron Gervais, {date.getFullYear()}, except where
          otherwise noted. All rights reserved.{' '}
          <Link to="/privacy-policy">Privacy Policy and CC Credits</Link>.
        </div>
      </div>
    </footer>
  )
}

export default Footer
