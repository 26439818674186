import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useNavStructure } from '../hooks'

import './SiteNav.sass'

const NavItem = ({ link, label }) => (
  <div className={`site-nav-item nav-item`}>
    <Link to={link} className="site-nav-link nav-link">
      {label}
    </Link>
  </div>
)

NavItem.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

const SiteNav = () => {
  const { navItems } = useNavStructure()
  return (
    <nav id="site-nav" className="site-nav nav">
      {navItems.map(({ label, link }) => (
        <NavItem key={uuidv4()} label={label} link={link} />
      ))}
    </nav>
  )
}

export default SiteNav
