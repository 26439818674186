import React, { Fragment, useCallback, useState, Element } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import SiteNav from './SiteNav'
import SocialNav from './SocialNav'
import SubNav from './SubNav'

import './NavContainer.sass'

const useHeadshot = () => {
  const query = useStaticQuery(
    graphql`
      query AaronHeadshotQuery {
        image: file(absolutePath: { regex: "/aaron-headshot-closer.jpg$/" }) {
          childImageSharp {
            fixed(width: 120, height: 120) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `,
  )
  query.image.alt = 'Aaron Gervais headshot'
  return query.image
}

const NavContainer = ({ targetRefs, subnav }) => {
  const headshot = useHeadshot()

  const [navOpen, setNavOpen] = useState(false)

  const toggleNav = useCallback(() => {
    setNavOpen(!navOpen)
  }, [navOpen])

  return (
    <nav className={`nav-container ${navOpen ? 'open' : 'closed'}`}>
      <button
        className={`nav-toggle`}
        aria-label="Toggle navigation menu"
        onClick={toggleNav}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div className="nav-container-intro">
        <hr />
        <SiteNav />
        <hr />
        <Link to="/" style={{ marginTop: '1rem', display: 'block' }}>
          <Img
            fixed={headshot.childImageSharp.fixed}
            alt={headshot.alt}
            className="nav-container-headshot"
          />
        </Link>
        <h3 className="nav-container-intro-title">Aaron Gervais</h3>
        <span className="nav-container-intro-subtitle">Brewer, Composer</span>
      </div>
      <div className="nav-container-children">
        {!!subnav && !!subnav.length && (
          <Fragment>
            <hr />
            {/* <h3>On this page</h3> */}
            <SubNav
              items={subnav}
              targetRefs={targetRefs}
              setNavOpen={setNavOpen}
            />
          </Fragment>
        )}
      </div>
      <div className="nav-container-footer">
        <SocialNav />
      </div>
    </nav>
  )
}

NavContainer.propTypes = {
  targetRefs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
  ),
  subnav: PropTypes.array,
}

export default NavContainer
